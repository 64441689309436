.GameDetailLogo{
    width: 80%;
    max-width: 250px;
    margin: 40px auto 0px;
}
.GameDetail{
    max-width: 800px;
    display: flex;
    margin: 0px auto;
    align-items: center;
    justify-content: space-around;
    margin-top: 20px;
}

.ChessBoardHeader{
    width: 420px;
    font-weight: bold;
    font-size: 18px;
    padding-bottom: 20px;
}
.GameDetailBtns{
    justify-content: center;
    display: flex;
    align-items: center;
}
.GameDetailRoom{
    text-align: center;
    font-weight: bold;
    color: #fff;
    width: 140px;
    height: 40px;
    line-height: 40px;
    background: #000;
    border-radius: 4px;
    text-decoration: none;
}
.GameDetailTutorial{
    text-align: center;
    font-weight: bold;
    color: #fff;
    width: 140px;
    height: 40px;
    line-height: 40px;
    background: #000;
    border-radius: 4px;
    margin-left: 10px;
}

.GameInfo{
    width: 280px;
    height: 534px;
    background: #fff;
    border: 4px solid #000;
    border-radius: 4px;
    padding: 10px;
    font-weight: bold;
    margin-top: 20px;
}
.Status{
    /* display: flex;
    justify-content: space-between; */
    margin-bottom: 20px;
}
.StatusValue0{
    margin-top: 6px;
    display: inline-block;
    background: #9AF1C3;
    color: #10A958;
    border-radius: 4px;
    padding: 2px 4px;
    border: 2px solid #10A958;
}
.StatusValue1{
    display: inline-block;
    background: #A6CFFF;
    color: #1575E5;
    border-radius: 4px;
    padding: 2px 4px;
    border: 2px solid #1575E5;
}
.StatusValue2{
    display: inline-block;
    color: #CF4A00;
    background: #FFB078;
    border-radius: 4px;
    padding: 2px 4px;
    border: 2px solid #CF4A00;
}
.PlayersValue{
    display: flex;
    margin-bottom: 10px;
    flex-wrap: wrap;
    align-items: center;
}
.PlayersTitle{
    margin-bottom: 10px;
    margin-top: 20px;
}
.PlayerName{
    margin-left: 10px;
}
.PlayerAddress{
    color: #A9B1C5;
    margin-left: 10px;
}
.Player1Color{
    width: 18px;
    height: 18px;
    background: #8610E3;
    border-radius: 2px;
}
.Player2Color{
    width: 18px;
    height: 18px;
    background: #FFB800;
    border-radius: 2px;
}
.NoPlayer2{

    display: flex;

}
.Player2Join{
    cursor: pointer;
    text-align: center;
    color: #fff;
    background: #000;
    border-radius: 4px;
   width: 80px;
   height: 30px;
   line-height: 30px;
    margin-left: 10px;
}
.InvitePlayer{
    text-align: center;
    color: #fff;
    background: #000;
    border-radius: 4px;
   width: 80px;
   height: 30px;
   line-height: 30px;
   margin-left: 10px;
   text-decoration: none;
}


.RoundTitle,.StatusTitle,.PlayersTitle{
    font-size: 18px;
}
.RoundValue{
    display: flex;
    margin-top: 10px;
}




.ChessBoardContainer{
 
    display: flex;
}
.ChessBoardContainerLeft{
    display: flex;
    flex-direction: column;
    height: 80px;
    line-height: 80px;
    font-weight: bold;
    margin-top: 20px;
    padding-right: 4px;
}
.ChessBoardContainerRightPosition{
    display: flex;
}
.ChessBoardContainerRightPosition li{
    width: 80px;
    text-align: center;
    font-weight: bold;
}
.ChessBoard{
    width: 400px;
    height: 560px;
    background: #fff;
    display: flex;
    flex-wrap: wrap;
    border: 2px solid #000;

    border-radius: 4px;
}
.ChessBoard li{
    cursor: pointer;
    width: 80px;
    height: 80px;
    background: #fff;
    box-sizing: border-box;
}


@keyframes blink {
    0% { border-color: red; }
    50% { border-color: #0047FF; }
    100% { border-color: red; }
  }
  

  

.normalPostion{
    border: 2px solid #000 ;
}
.selectedPostion{

    
    border: 6px solid yellow;  
    animation: blink 1s infinite;

}
.moveAbleBg{
    width: 77px;
    height: 77px;
    background: #A9B1C5;
}
.Player1CastleBg{
    width: 77px;
    height: 77px;
    background: url("../../assets/player1/castle.png");
    background-size: contain;
   
}
.Player2CastleBg{
    width: 77px;
    height: 77px;
    background: url("../../assets/player2/castle.png");
    background-size: contain;
}
.GameActivity{
    max-width: 760px;
    margin: 0px auto;
    margin-top: 40px;
}
.GameActivityTitle{
    max-width: 740px;
    font-size: 18px;
    font-weight: bold;
    margin: 0px auto;
}
.GameActivityList{
    max-width: 720px;
    background: #fff;
    border: 4px solid #000;
    border-radius: 4px;
    height: auto;
    padding: 10px;
    margin-left: 10px;
    margin-top: 10px;
}
.GameActivityList li{
    justify-content: space-between;
    display: flex;
}
.GameActivityPlayer{
    font-size: 14px;
    color: #fff;
    padding: 2px 4px;
    display: inline-block;
    background: #8610E3;
    border-radius: 2px;
}
.Round{
    margin-top: 20px;
}
.Winner{
    margin-top: 20px;
}
.WinnerTitle{
    font-size: 18px;
}
.WinnerPlayer{
    display: flex;
    align-items: center;
}
.WinnerIcon{
    margin-left: 10px;
}
.ApplyWinButton{
    margin: 0px auto;
    margin-top: 20px;
    width: 80%;
    text-align: center;
    line-height: 40px;
    height: 40px;
    background: #0C994F;
    color: #fff;
    cursor: pointer;
    border: 4px solid #000;
    border-radius: 4px;
    
}
.ChessContainer{
    padding-bottom: 100px;
}
.ApplyWinText{
    font-size: 16px;
    margin-top: 20px;
    color: #666;
}
.CopyRoomId{
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.CopyRoomIdButton{
    cursor: pointer;
    text-align: center;
    color: #fff;
    background: #000;
    border-radius: 4px;
   width: 140px;
   height: 30px;
   line-height: 30px;
    margin-left: 10px;
}

@media only screen and (max-width: 576px) {

    .GameDetail{
        flex-direction: column;
    }
}
