.WalletPannel{

    font-weight: bold;
    max-width: 500px;
    width: 90%;
    margin: 40px auto 0px;
    padding: 10px 0px 30px;
    border: 4px solid #000;
    background: #fff;
    border-radius: 4px;
    padding: 10px;
    padding-bottom: 30px;
   
}
.WalletPannelConnect{

    font-weight: bold;
    max-width: 500px;
    width: 90%;
    margin: 40px auto 0px;
    padding: 20px;
    border: 4px solid #000;
    border-radius: 4px;
    background: #fff;
    padding-top: 40px;
}
.WalletAddress{
    width: 90%;
    text-align: center;
    margin: 0px auto;
}
.WalletAddressConnect{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0px auto;
    padding-bottom: 20px;

}
.NetworkList{
    margin: 0px;
    padding: 0px;
}
.NetworkList li{

}

.NetworkButton{
    text-align: center;
    cursor: pointer;

    color: #332018;
    width: 80%;
    margin: 0px auto;
    padding: 8px 0px;
    margin-top: 20px;
    border-radius: 4px;
    border: 4px solid #000;
    background: #FEE333;
    padding: 20px 0px;
}
.NetworkInfo{
    color: #000;
    font-weight: normal;
    margin-top: 10px;
    /* text-decoration: none; */
    display: block;
    width: 100%;
    text-align: center;
}

.NetworkTitle,.WalletTitle{
    font-size: 18px;
    text-align: center;
    width: 100%;
    margin-top: 20px;
}

.WalletBtn {
    font-weight: bold;
    width: 144px;
    text-align: center;
    line-height: 48px;
    height: 48px;
    /* background: url("../../assets/CreateButtonBg.png");
    background-size: contain;
    color: #000; */
    cursor: pointer; 
    margin: 0px auto;
    margin-top: 20px;
    background: #FEE333;
    border-radius: 4px;
    border: 4px solid #000;
}


.NetworkList li:hover,.WalletBtn:hover{
    /* border-image: url("../../assets/ui/ButtonHover.png")  12 fill; */
}
.GameScore{
    align-items: center;
    display: flex;
    justify-content: space-around;
    margin-top: 20px;
}
.GameScore li{
    align-items: center;
    display: flex; 
}

.BurnWalletTab{
    width: 200px;
    display: flex;
    font-weight: bold;
    border: 4px solid #000;
    border-radius: 4px;
    cursor: pointer;
    margin: 0px auto;
}
.BurnerWallerTip{
    width:calc(100% - 20) ;
    background: #FFB6C5;
    border: 3px solid #FF3D66;
    color: #FF3D66;
    padding: 10px;
    margin: 0px auto;
    margin-top: 20px;
    border-radius: 4px;
}
.BurnWalletTabSelect{
    width: 100px;
    height: 36px;
    line-height: 36px;
    background: #000;
    color: #fff;
    text-align: center;
}
.BurnWalletTabNormal{
    width: 100px;
    height: 36px;
    line-height: 36px;
    color: #000;
    text-align: center; 
}

.BurnWalletCreate{
    width: 100px;
    text-align: center;
    line-height: 40px;
    height: 40px;
    background: #0C994F;
    color: #fff;
    cursor: pointer;
    margin-right: 20px;
    border: 3px solid #000;
    border-radius: 4px;
    font-weight: bold;
    margin: 0px auto;
    margin-top: 20px;
}

.BurnWalletNew{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
}
.BurnWalletInput{
    border: 3px solid #000;
    width: calc(100% - 8px);
    border-radius: 4px;
    padding: 10px 4px;
    margin-top: 20px;
}
.BurnWalletNewInfo{
    width: 60%;
  
}
.AddBurnWalletBtn{
    cursor: pointer;
    font-weight: bold;
    border: 3px solid #000;
    border-radius: 4px;
    padding: 4px;
}
.BurnWalletNewInfo span{
    font-weight: bold;
}
.BurnWalletGenerate{
    cursor: pointer;
    font-weight: bold;
    border: 3px solid #000;
    border-radius: 4px;
    padding: 4px;
}
.changeBurnWallet{
    cursor: pointer;
    font-weight: bold;
    border: 3px solid #000;
    border-radius: 4px;
    padding: 4px; 
}
.BurnWalletLoading{
    width: 100%;
    text-align: center;
    font-size: 14px;
    margin-top: 20px;
    font-weight: bold;
}
.BurnWalletAddress{
    cursor: pointer;
    text-align: right;
    display: flex;
    align-items: center;
}
.myBurnWalletMenu{
    
    width: 150px;
    border: 3px solid #000;
    border-radius: 3px;
    background: #fff;
}
.myBurnWalletMenuItem{
    width: 100%;
    text-align: center;
    padding: 10px 0px;
    cursor: pointer;
}
.myBurnWalletMenuItem:hover{
    color: #fff;
    background: #000;
}
.ant-modal .ant-modal-content{
    border:4px solid #000;
}
.GameScoreContainer{
    width: 100%;
}
.GameScoreTitle{
    /* font-size: 24px; */
}
.ant-message .ant-message-notice-wrapper .ant-message-notice-content{
    border: 3px solid #000;
}