.Banner{
    padding-top: 40px;
    padding-bottom: 20px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.BannerLeft{
    width: 20%;
}
.BannerRight{
    width: 20%;
}

.BannerLogo{
    width: 80%;
    max-width: 250px;
    margin: 0px auto;
}
.BannerSologan{
    font-weight: bold;
    font-size: 28px;
    text-align: center;
    width: 100%;
    color: #000;

}
.BannerBtns{
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 90px;
}
.TutorialBtn{
    cursor: pointer;
    text-align: center;
    font-weight: bold;
    color: #fff;
    width: 140px;
    height: 40px;
    line-height: 40px;
    background: #000;
    border-radius: 4px;
    margin-right: 10px;
}
.TwitterBtn{
    text-decoration: none;
    cursor: pointer;
    text-align: center;
    font-weight: bold;
    color: #fff;
    width: 140px;
    height: 40px;
    line-height: 40px;
    background: #000;
    border-radius: 4px;
    margin-left: 10px;
}
.RoomList{
    /* padding-bottom: 200px; */
}
.RoomList li {
    display: flex;
    width: 90%;
    margin: 0px auto;
    padding: 20px;
    border: 4px solid #000;
    align-items: center;
    justify-content: space-between;
    border-radius: 4px;
    max-width: 500px;
    background: #fff;
    margin-top: 20px;
    font-weight: bold;
}

.RoomInfo {}
.RoomId{
    display: flex;
    align-items: center;
}
.RoomIdText{

}
.RoomPlayers{
    display: flex;
    margin-top: 10px;
}
.RoomPlayer1,.RoomPlayer2{
    display: flex;
    color: #A2A2A2;
}
.RoomPK{
    display: block;
    margin-left: 4px;
    margin-right: 4px;
}
.RoomIdNumber{
    display: inline-block;
    background-color: yellow; /* 设置背景色 */
    padding: 2px 4px; 
    background: #000;  color: #fff;
    border-radius: 2px;
    margin-left: 10px;

}
.RoomButtons {
    display: flex;
    flex-direction: row;
}
.RoomButtons a{
    text-decoration: none;
}
.JoinRoomButton {
    width: 100px;
    text-align: center;
    line-height: 40px;
    height: 40px;
    background: #0C994F;
    color: #fff;
    cursor: pointer;
    margin-right: 20px;
    border: 4px solid #000;
    border-radius: 4px;
}

.ViewRoomButton {
    width: 100px;
    text-align: center;
    line-height: 40px;
    height: 40px;
    background: #A742F7;
    color: #fff;
    cursor: pointer;
    border: 4px solid #000;
    border-radius: 4px;
}

.ViewRoomButton a{
    color: #fff;
    text-decoration: none;
}

.RoomHead{
    display: flex;
    margin: 40px auto;
    max-width: 540px;
    align-items: center;
    justify-content: space-between;
}
.RoomTab{
    width: 140px;
    display: flex;
    font-weight: bold;
    border: 4px solid #000;
    border-radius: 4px;
    cursor: pointer;
}
.RoomTabSelect{
    width: 70px;
    height: 36px;
    line-height: 36px;
    background: #000;
    color: #fff;
    text-align: center;
}
.RoomTabNormal{
    width: 70px;
    height: 36px;
    line-height: 36px;
    color: #000;
    text-align: center; 
}

.RoomHeadTitle{
    font-size: 24px;
    font-weight: bold;
}
.CreateRoom {
    font-weight: bold;
    width: 144px;
    text-align: center;
    line-height: 52px;
    height: 56px;
    background: url("../../assets/CreateButtonBg.png");
    background-size: contain;
    color: #000;
    cursor: pointer; 
}
.CreateRoom:hover{
    transform: translateY(-2px);
}
.LoadMore{
    text-align: center;
    font-weight: bold;
    color: #fff;
    width: 120px;
    height: 40px;
    line-height: 40px;
    background: #000;
    border-radius: 4px;
    margin: 30px auto 30px;
    cursor: pointer;
}
.NoRooms{
    width: 100%;
    text-align: center;
    font-weight: bold;
}
.Rooms{
    padding-bottom: 100px;
}
.GameDetailRoom a{
    text-decoration: none;
    color: #fff;
}
.BuiltOn{
    margin: 0px auto;
    width: 60%;
    text-align: center;
    margin-top: 20px;
    font-size: 18px;

}
.BuiltOn a{
    color: #000;
    text-decoration: underline;
}
.RoomContainer{
    padding-bottom: 100px;
}