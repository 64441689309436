.Tutorial{
    width: 100%;
    height: 355px;
    overflow: scroll;
}
.tutorial_hereos li{
    display: flex;
    align-items: center;
    
}
.tutorial_hereos li img{
    width: 32px;
    height: 32px;
    margin-right: 10px;
}